import { Injectable } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})

export class ReportService {
  base_uri;
  quickbooks_uri;

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    maxHeight:"100px"
};
  constructor(private authService: NbAuthService, private http: HttpClient, private toastrService: NbToastrService) {
    this.base_uri = environment.reports_url;
    this.quickbooks_uri = 'https://quickbooks.api.intuit.com/v3/company/123146137542759/query';

  }

  getSelectedIds(data , isPaymentModes) {
    let Ids = [];
    if(data && data.length > 0) {
      if(!isPaymentModes){
      data.forEach(item => {
        let data = Object.keys(item);
        const find = data.findIndex(i => i == 'id');
        if(find >= 0)  Ids.push(item.id);
        else if(find < 0 ) Ids.push(item.type_id);     
      });
    } else {
      data.forEach(item => {
        Ids.push(item);
      });
    }
    } else Ids = [];
    return Ids;
  }

  getQuickbooksToken(refresh_token?) {
    let url = this.base_uri + "quickbooks/get_token";

    if(refresh_token){
      url += "?refresh_token=" + refresh_token;
    }

    return this.http.get(url, {
    });
  }

  async getPanels(id) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/get_panel?hospital_id=' + id;
    return this.http.get(url, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async getAllServiceTypes(hospital) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/service_type?hospital_id=" + hospital;
    return this.http.get(url, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async getAccountResetItems(type) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/get_reset_items?type=" + type;
    return this.http.get(url, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async getAllServiceSubTypes(hospital) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/service_sub_type?hospital_id=" + hospital;
    return this.http.get(url, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async getAllServiceCategory(hospital) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/service_category?hospital_id=" + hospital;
    return this.http.get(url, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async copyCost(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/copy_cost'
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    });
  }

  async resetOrganization(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/reset_organization'
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }


  async getRateList(data,name) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/download_rate_list'
    // return this.http.post(url, data, {
    //   headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    // });

    this.http.post(url, data, { responseType: 'blob',headers: new HttpHeaders().set('Authorization', 'Bearer ' + token), })
 .subscribe((resp: any) => {
    FileSaver.saveAs(resp, `${name? name : ''}_Rate_List.csv`)
 },err =>{
  console.log('error in downloading',err);
  this.showToast('bottom-right', 'danger', 'Services does not exist');
 });
  }

  async getFilerRateList(data,name) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/download_rate_list'
    // return this.http.post(url, data, {
    //   headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    // });

    this.http.post(url, data, { responseType: 'blob',headers: new HttpHeaders().set('Authorization', 'Bearer ' + token), })
 .subscribe((resp: any) => {
    FileSaver.saveAs(resp, `${name? name : ''}_Rate_List.csv`)
 },err =>{
  console.log('error in downloading',err);
  this.showToast('bottom-right', 'danger', 'Services does not exist');
 });
  }

  async getStock(data,name) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/stock_download'
    this.http.post(url, data, { responseType: 'blob',headers: new HttpHeaders().set('Authorization', 'Bearer ' + token), })
 .subscribe((resp: any) => {
    FileSaver.saveAs(resp, `${name? name : ''}_stock.csv`)
 },err =>{
  console.log('error in downloading',err);
  this.showToast('bottom-right', 'danger', 'Stock not downloaded');
 });
  }

  async getConnections(id) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/get_connections?hospital_id=' + id;
    return this.http.get(url, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async getOrganization(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/get_organization';
    return this.http.post(url, data,{
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async saveConnection(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/save_connection';
    return this.http.post(url, data,{
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async deleteBulkServices(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/service_delete';
    return this.http.post(url, data,{
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async deleteBulkCosts(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/bulk_cost_delete';
    return this.http.post(url, data,{
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }


  async deleteConnection(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/delete_connection';
    return this.http.post(url, data,{
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async changeRole(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/change_role';
    return this.http.post(url, data,{
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }
  async getVisits(id) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/get_visits?hospital_id=' + id;
    return this.http.get(url, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async updateVisit(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/update_visit';
    return this.http.post(url, data,{
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async getIds(id) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/get_ids?hospital_id=' + id;
    return this.http.get(url, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }
  async updateIds(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/update_id';
    return this.http.post(url, data,{
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async uploadFile(data,type) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/' + type;
    return this.http.post(url, data,{
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async uploadSurgeryEvent(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/upload_service';
    return this.http.post(url, data,{
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async connectLabTest(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/connect_labtest';
    return this.http.post(url, data,{
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  quickBooksToken(){
    let token = localStorage.getItem('access_token');
    return token
  }

  async fetchInvoices(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "quickbooks/get_data";
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async resetPasswords(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/reset_password";
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status,
      `${message}`,
      { position, status });
  }

  async resetMultiple(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/reset_multiple";
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async connectUsers(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/connect_users";
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async getHospitalServices(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/get_hospital_services";
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async getConnectedUsers(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/get_user_relations?";
    if(data.user_id){
      url += "user_id=" + data.user_id + "&";
    }
    if(data.related_user_id){
      url += "related_user_id=" + data.related_user_id + "&";
    }
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async updateUserRelations(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/update_user_relations";
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async getStockTransferConnections(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/get_stock_transfer_connections?";
    if(data.master_id){
      url += "master_id=" + data.master_id + "&";
    }
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async createPharmacyConnection(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/create_pharmacy_connection";
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async updatePharmacyConnection(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/update_pharmacy_connection";
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async getStoreModulePermissions(){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/get_store_module_permissions";
    return this.http.post(url, {}, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async createStoreModulePermissions(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/create_store_module_permission";
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async updateStoreModulePermissions(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/update_store_module_permission";
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async getBillToShipToConnections(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/get_organization_relations?";
    if(data.parent_id){
      url += "parent_id=" + data.parent_id + "&";
    }
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async createOrganizationRelation(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/create_organization_relation";
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async updateOrganizationRelation(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/update_organization_relation";
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }
  
  async transferService(data){
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + "sales_dashboard/transfer_services";
    return this.http.put(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  getTickets(data:any){
    let authToken = this.authService.getToken();

    let url = this.base_uri + 'ticket/get';

    if(data.page > -1){
      url += '?page=' + data.page
    }

    if(data.flags && data.flags.include_closed){
      url += '&include_closed=' + data.flags.include_closed
    }

    if(data.flags && data.flags.archived){
      url += '&archived=' + data.flags.archived
    }

    return this.http.get<any>(url, {
      observe:'response',
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + authToken)
    });
  }

  async getCustomFields(){

    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'ticket/get_custom_fields';

    return this.http.get(url, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token)
    }).toPromise();
  }

  async saveTicket(data:any){
    let authToken = await this.authService.getToken().toPromise();

    let url = this.base_uri + 'ticket/create';

    return this.http.post<any>(url, data, {
      observe:'response',
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + authToken)
    }).toPromise();
  }

  async copyPrintTemplates(data: any) {
    let authToken = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/copy_print_templates';
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + authToken)
    }).toPromise();
  }

  async getLabServices(hospital_id) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/get_lab_services?hospital_id=' + hospital_id;
    return this.http.get(url, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async getLabTests(lab_id: number, test_only: number) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/get_lab_tests?' +'lab_id=' + lab_id + '&test_only=' + test_only;
    return this.http.get(url, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async getAllianceServiceMap(lab_id) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/get_alliance_service_map?lab_id=' + lab_id;
    return this.http.get(url, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async mapLabTest(data: any) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/map_lab_test';
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async completeMapping(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/set_tests_names';
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async deleteRecords(data) {
    let token = await this.authService.getToken().toPromise();
    let url = this.base_uri + 'sales_dashboard/delete_records';
    return this.http.post(url, data, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
    }).toPromise();
  }

  async copyServiceCost(data) {
    let url = this.base_uri + "sales_dashboard/copy_service_cost";
    let token = await this.authService.getToken().toPromise();
    return this.http.post(url, data, {
      headers: new HttpHeaders().set(
        "Authorization",
        "Bearer " + token
      )
    }).toPromise();
  }
}