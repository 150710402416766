import { Component, Input } from '@angular/core';
import { NbAuthService, NbTokenService } from '@nebular/auth';
import { Router } from '@angular/router';
import { ReportService } from '../../../dashboard/services/report.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed >
        <ngx-header [user]="user"></ngx-header>
        <a class="side-btn" (click)="logout();">logout</a>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  @Input() user;
  constructor(private authService: NbAuthService, private tokenService: NbTokenService, private router: Router, private reportService: ReportService) {

  }

  async ngOnInit(){
  }

  // async getQuickbooksToken(refresh?){
  //   let response = await this.reportService.getQuickbooksToken(refresh ? refresh : undefined).toPromise();

  //   if(response['code'] == 200){
  //     let data = response['data']['results'];
  //     localStorage.setItem("access_token", data[0]['access_token']);
  //   }
  // }

  logout() {
    this.authService.logout('email');
    this.tokenService.clear();
    this.router.navigate(['/auth/login']);
  }
}
